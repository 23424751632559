import React from 'react'
import styled from 'styled-components'
import { Container, Flex, Text, Box, theme } from '@hackclub/design-system'
import whatsapp from '../assets/imgs/whatsapp.png'
import phone from '../assets/imgs/phone.png'

const Services = styled(Container)`
  display: flex;
  position: fixed;
  top: 0px;
  right: 10px;
  z-index: 1000;
  @media (max-width: 1441px) {
    right: -40px;
  }
  @media (max-width: 1141px) {
    right: -55px;
  }
  @media (max-width: 1110px) {
    display: none;
  }
  div {
    p {
      margin: 26px 0px 0px -2px;
    }
    > div {
      width: 40px;
      height: 40px;
    }
  }
  svg {
    color: blue;
    width: 32px;
    height: 32px;
    transition: 0.5s;
    &:hover {
      color: #4166f5;
      transform: scale(1.2);
    }
  }
  ${theme.mediaQueries.md} {
    max-width: 400px;
  }
`
Services.defaultProps = {
  align: 'center',
  mb: 3,
  wrap: true
}
const Whatsapp = styled(Box)`
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-left: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${whatsapp});
`
const Phone = styled(Box)`
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-left: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${phone});
`
const Service1 = ({ text, ...props }) => (
  <Flex>
    <Whatsapp/>
  </Flex>
)
const Service2 = ({ text, ...props }) => (
  <Flex>
    <Phone />
    <Text mt={4}>
      {text}
    </Text>
  </Flex>
)

const Social = () => {
  return (
    <>
      <Services>
        <Service1 icon="messenger" />
        <Service2 icon="message-simple-fill" text="+91 9951013134" />
      </Services>
    </>
  )
}

export default Social
