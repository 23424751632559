import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import {
  Heading,
  Text,
  Container,
  theme,
  Box,
  Section,
  Sheet,
  LargeButton,
  Link as A
} from '@hackclub/design-system'

import codeImg from '../assets/imgs/code.jpg'
import { Link } from 'gatsby'

A.link = A.withComponent(Link)

let svg='';

export const contentContainer = {
  maxWidth: 72,
  width: 1,
  p: 3,
  color: 'black',
  style: { position: 'relative' }
}

export const content = { maxWidth: 48, mx: 0, color: 'black' }

export const FeatureLink = styled(A.link).attrs({
  mt: 3,
  fontSize: 3
})`
  display: block;
  @media (hover: hover) {
    border-bottom: 2px solid white;
  }
`

export const shadows = css`
  h1,
  h2,
  h3,
  p,
  li,
  ${FeatureLink} {
    color: ${theme.colors.white};
    text-shadow: 0 1px 4px rgba(0, 0, 0, 1);
  }
`

export const Title = styled(Heading.h1).attrs({
  fontSize: [7, 8, 9],
  mb: [3, 4]
})`
  letter-spacing: -0.03125em;
  line-height: 1;
`

export const photoSection = css`
  border-radius: ${theme.radii[2]};
  background-size: cover;
  @media (hover: hover) {
    background-attachment: fixed;
  }
`
export const PhotoHeader = styled(Section).attrs({ px: 0 })`
  background-image: linear-gradient(
      ${props =>
        props.inverted
          ? 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)'
          : 'rgba(0, 0, 0, 0.325), rgba(0, 0, 0, 0.5)'}
    ),
    url(${props => props.src});
  background-position: center;
  ${photoSection};
  ${shadows};
`
export const MapBox = styled(PhotoHeader).attrs({ px: 0 })`
  background-color: ${theme.colors.dark};
  background-image: url(${codeImg});
  background-repeat: no-repeat;
  background-position: 20% top;
  ${photoSection};
  ${theme.mediaQueries.md} {
    background-position: center 20%;
  }
`

export const Headline = styled(Heading.h2).attrs({
  fontSize: [6, 7],
  mb: [3, 4]
})`
  display: block;
  line-height: 1;
  letter-spacing: -0.02em;
`

export const Subhline = styled(Heading.h3).attrs({
  fontSize: [5, 6],
  bold: true,
  mb: 2
})`
  line-height: 1.125;
  letter-spacing: -0.02em;
`

export const Featline = styled(Heading.h3).attrs({
  fontSize: 5,
  mb: 3,
  bold: true
})`
  line-height: 1;
  letter-spacing: -0.01em;
`

export const gradientText = css`
  color: ${({ colors }) => theme.cx(colors[2])};
  @supports (-webkit-background-clip: text) {
    background-image: linear-gradient(
      to right,
      ${({ colors }) => theme.cx(colors[0])} 25%,
      ${({ colors }) => theme.cx(colors[1])} 50%,
      ${({ colors }) => theme.cx(colors[2])}
    );
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
// Colored text needs pb: 2 to avoid cropping descenders
export const ColoredTitle = styled(Title).attrs({ pb: 2 })`
  ${gradientText}
`
export const ColoredHeadline = styled(Headline).attrs({
  pb: 2,
  mb: [2, 3],
  maxWidth: 54
})`
  ${gradientText}
`

export const Lead = styled(Container.withComponent(Text)).attrs({
  fontSize: [3, 4]
})``

export const Highlight = styled(Text.span)`
  border-radius: 1em;
  background-image: linear-gradient(
    -100deg,
    ${theme.hexa('yellow.2', 0.33)},
    ${theme.hexa('yellow.2', 0.95)},
    ${theme.hexa('yellow.2', 0.1)}
  );
`

const highlighter = keyframes`
  0% {
    background-image: linear-gradient(
      -100deg,
      ${theme.hexa('yellow.2', 0.33)},
      ${theme.hexa('yellow.2', 0.33)}
    );
    background-size: 0 100%;
  }
  50% {
    background-image: linear-gradient(
      -100deg,
      ${theme.hexa('yellow.2', 0.33)},
      ${theme.hexa('yellow.2', 0.95)},
    );
  }
  100% {
    background-image: linear-gradient(
      -100deg,
      ${theme.hexa('yellow.2', 0.33)},
      ${theme.hexa('yellow.2', 0.95)},
      ${theme.hexa('yellow.2', 0.1)}
    );
    background-size: 100% 100%;
  }
`

export const AnimatedHighlight = styled(Text.span)`
  border-radius: 1em;
  animation: ${highlighter} 3s ease forwards;
  animation-delay: ${props => props.delay || 6}s;
  background-repeat: no-repeat;
  background-size: 0 100%;
`

export const FaqHeader = styled(Box.withComponent('header'))`
  background: url(${svg});
  > div {
    display: grid;
    grid-gap: ${theme.space[4]}px;
    ${theme.mediaQueries.md} {
      grid-template-columns: 3fr 2fr;
    }
  }
`
export const TeamHeader = styled(Section)`
  background: url(${svg}), ${theme.gradient('warning', 'primary')};
  background-repeat: repeat;
`

export const Background = styled(Section)`
  background-color: ${theme.colors.indigo[5]};
  background-image: url(${svg}),
    linear-gradient(64deg, #f88bf9, #00d930, #f88bf9 100%);
  opacity: 0.8;
`
export const CareerBackground = styled(Section)`
  background-color: ${theme.colors.indigo[5]};
  background-image: url(${svg}),
    linear-gradient(64deg, #6a5acd, #00c930, #6a5acd 100%);
  opacity: 0.8;
`
export const PrivacyBackground = styled(Section)`
  background-color: ${theme.colors.indigo[5]};
  background-image: url(${svg}),
    linear-gradient(
      -64deg,
      ${theme.colors.indigo[5]},
      ${theme.colors.violet[5]},
      ${theme.colors.violet[6]}
    );
`
export const WorkshopBackground = styled(Section)`
  background-color: ${theme.colors.indigo[5]};
  background-image: url(${svg}),
    linear-gradient(64deg, #db5ea9, #e4081c, #db5ea9 100%);
  opacity: 0.8;
`

export const CorporateBackground = styled(Section)`
  background-color: ${theme.colors.indigo[5]};
  background-image: url(${svg}),
    linear-gradient(64deg, #008b84, #90c956, #90c956 100%);
`

export const AboutHeader = styled(Section.withComponent('header')).attrs({
  bg: 'fuschia.6'
})`
  background-image: linear-gradient(
    32deg,
    ${theme.colors.green[5]} 0%,
    ${theme.colors.red[5]} 64%,
    ${theme.colors.red[6]} 100%
  );
  height: 400px;
  > div {
    position: relative;
  }
`

export const GalleryHeader = styled(Section.withComponent('header')).attrs({
  bg: 'fuschia.6'
})`
  background-image: linear-gradient(
    32deg,
    ${theme.colors.fuschia[5]} 0%,
    ${theme.colors.red[5]} 64%,
    ${theme.colors.red[6]} 100%
  );
  > div {
    position: relative;
  }
`

export const featureStyles = css`
  min-height: 24rem;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p:first-child,
  h3 {
    line-height: 1.25;
  }
`
export const TextFeature = styled(Sheet)`
  > p {
    font-weight: bold;
    font-size: ${theme.fontSizes[5]}px;
    line-height: 1.25;
  }
`

export const Slider = styled(Section).attrs({
  color: 'white'
})`
  position: relative;
  border-radius: ${theme.radii[2]};
  background-color: ${theme.colors.primary};
  ${props =>
    props.inverted
      ? css`
          justify-content: flex-end !important;
          background-image: linear-gradient(
              'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)'
            ),
            url(${props.src});
        `
      : css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.3),
              rgba(0, 0, 0, 0.2) 50%
            ),
            url(${props.src});
        `};
  background-position: center;
  background-size: cover;
  background-repeat: norepeat;
  ${shadows};
  height: 570px;
`
export const PhotoFeature = styled(TextFeature).attrs({
  color: 'white'
})`
  position: relative;
  &:hover {
    transform: scale(1.07);
    cursor: pointer;
  }
  ${props =>
    props.inverted
      ? css`
          justify-content: flex-end !important;
          background-image: linear-gradient(
              transparent,
              rgba(0, 0, 0, 0.25) 50%,
              rgba(0, 0, 0, 0.5) 100%
            ),
            url(${props.src});
        `
      : css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0) 50%
            ),
            url(${props.src});
        `};
  &:hover {
    transition: transform 0.8s ease-out;
  }
  background-position: center;
  background-size: cover;
  background-repeat: norepeat;
  ${shadows};
  + ${Text} {
    color: ${theme.colors.black};
    font-size: ${theme.fontSizes[3]}px;
    margin-top: ${theme.space[3]}px;
  }
`

export const BankFeature = styled(Sheet)`
  background-color: ${theme.colors.primary};
  background-image: ${theme.gradient('red.5', 'red.7')};
`
export const HomeSectionLead = styled(Lead).attrs({
  fontSize: [3, 4],
  maxWidth: 88,
  mx: 0,
  pb: 3,
  mb: [4, 5]
})``

export const SectionLead = styled(Lead).attrs({
  fontSize: [3, 4],
  maxWidth: 48,
  mx: 0,
  pb: 3,
  mb: [4, 5]
})``

export const CareerSectionLead = styled(Lead).attrs({
  fontSize: [2, 3],
  maxWidth: 100,
  mx: 0,
  pb: 2,
  mb: [4, 5]
})``

export const Ultraline = styled(Heading.h1).attrs({
  fontSize: [5, 7, 8, 9],
  color: 'white',
  caps: true
})`
  line-height: 1.125 !important;
  &:nth-of-type(2) {
    padding-left: 1.5rem;
    ${theme.mediaQueries.md} {
      padding-left: 6rem;
    }
  }
  &:nth-of-type(3) {
    text-align: center;
  }
  &:nth-of-type(4) {
    text-align: right;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background-color: rgba(252, 252, 252, 0.625);
      mix-blend-mode: overlay;
      right: -0.5rem;
      width: 9.5rem;
      height: 2.5rem;
      ${theme.mediaQueries.sm} {
        width: 20rem;
        height: 5.5rem;
      }
    }
  }
`

export const CareerRow = styled(Box)`
  text-align: left;
  ${theme.mediaQueries.md} {
    display: grid;
    grid-gap: ${theme.space[3]}px;
    grid-template-columns: ${({ reverse }) =>
      reverse ? '3fr 2fr' : '2fr 3fr'};
  }
`

export const CareerBase = styled(Container)`
  display: grid;
  grid-gap: 1rem;
  ${theme.mediaQueries.md} {
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
`
export const TeamBase = styled(Container)`
  display: grid;
  grid-gap: 1rem;
  ${theme.mediaQueries.md} {
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
`
export const WorkshopBase = styled(Container)`
  display: grid;
  grid-gap: 1rem;
  ${theme.mediaQueries.md} {
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
  > div:hover {
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.309);
  }
`

export const CareerDiv = styled(Sheet)`
  display: flex;
  border-radius: ${theme.radii[2]};
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.309);
  transition: 0.8s;
  cursor: pointer;
  max-width: 60rem;
  @media (max-width: 424px) {
    // display: none;
    flex-direction: column;
    img {
      margin-left: 20px;
    }
    > div {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
    }
  }
  img {
    flex-shrink: 0;
  }
`

export const WorkshopDiv = styled(Sheet)`
  display: flex;
  border-radius: ${theme.radii[2]};
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.309);
  transition: 0.8s;
  cursor: pointer;
  max-width: 60rem;
  @media (max-width: 550px) {
    flex-direction: column;
  }
  img {
    flex-shrink: 0;
  }
  a {
    @media (max-width: 685px) {
      margin-top: 20px;
    }
  }
`

export const AboutCols = styled(Box)`
  display: grid;
  grid-gap: ${theme.space[3]}px;
  ${theme.mediaQueries.sm} {
    grid-gap: ${theme.space[4]}px;
    grid-template-columns: ${props => props.cols};
  }
  > div,
  > section > div {
    ${featureStyles};
  }
`
AboutCols.defaultProps = {
  cols: '1fr 1fr',
  my: [3, 4]
}

export const Steps = styled(Box)`
  display: grid;
  grid-gap: ${theme.space[3]}px;
  ${theme.mediaQueries.md} {
    grid-gap: ${theme.space[4]}px;
    grid-template-columns: repeat(3, 1fr);
  }
  > div {
    ${featureStyles};
  }
`

export const HourFeatures = styled(Steps)`
  section div {
    min-height: 22rem;
    justify-content: flex-start;
  }
  div + p {
    margin-bottom: ${theme.space[4]}px;
  }
`

export const ApplyButton = styled(LargeButton).attrs({
  scale: true,
  chevronRight: true
})`
  text-transform: uppercase;
  background-image: ${theme.gradient('warning', 'primary')};
`

export const StepOne = styled(Sheet)`
  height: 350px;
  background-color: ${theme.colors.red[6]};
  background-image: linear-gradient(
    to bottom,
    ${theme.colors.orange[5]},
    ${theme.colors.pink[6]}
  );
`
export const StepTwo = styled(Sheet)`
  height: 350px;
  background-color: ${theme.colors.violet[6]};
  background-image: linear-gradient(
    to bottom,
    ${theme.colors.fuschia[5]},
    ${theme.colors.indigo[6]}
  );
`
export const StepThree = styled(Sheet)`
  height: 350px;
  background-color: ${theme.colors.teal[6]};
  background-image: linear-gradient(
    to bottom,
    ${theme.colors.cyan[5]},
    ${theme.colors.blue[6]}
  );
`

export const SectionEyebrow = styled(Text).attrs({
  fontSize: [4, 5],
  bold: true,
  color: 'muted'
})``

export const SectionHeadline = styled(Headline).attrs({
  fontSize: [6, 7, 8],
  mt: 2,
  mb: 3
})`
  line-height: 1;
`

export default () => <></>
